export enum PermissionEnum {
	INTERNAL_CUSTOMER_FILE_USER_MANAGE = 'internal-customer-file-user-manage',
	INTERNAL_GROUP_MANAGE = 'internal-group-manage',

	EXTERNAL_CUSTOMER_FILE_USER_MANAGE = 'external-customer-file-user-manage',
	EXTERNAL_GROUP_MANAGE = 'external-group-manage',

	IMPERSONATE = 'impersonate',
	THEME_MANAGE = 'theme-manage',

	CUSTOMER_FILE_MANAGE = 'customer-file-manage',
	DOMAIN_MANAGE = 'domain-manage',
	WIDGET_CUSTOMER_FILE_MANAGE = 'widget-customer-file-manage',
	WIDGET_DOMAIN_MANAGE = 'widget-domain-manage',

	// ACCOUNTING
	ACCOUNTING_READ = 'accounting-read',
	ACCOUNTING_SETTING = 'accounting-setting',
	ACCOUNTING_SYNCHRONIZE = 'accounting-synchronize',

	// DOCUMENT
	DOCUMENT_DOWNLOAD = 'document-download',
	DOCUMENT_UPLOAD = 'document-upload',
	DOCUMENT_SETTING_STORAGE = 'document-setting-storage',
	DOCUMENT_SETTING_TAG = 'document-setting-tag',

	// QUICKENTRY
	QUICKENTRY_VOUCHER_READ = 'quickentry-voucher-read',
	QUICKENTRY_VOUCHER_WRITE = 'quickentry-voucher-write',
	QUICKENTRY_VOUCHER_VALIDATE = 'quickentry-voucher-validate',
	QUICKENTRY_BILL_EXCHANGE_READ = 'quickentry-bill-exchange-read',
	QUICKENTRY_BILL_EXCHANGE_WRITE = 'quickentry-bill-exchange-write',
	QUICKENTRY_BILL_EXCHANGE_VALIDATE = 'quickentry-bill-exchange-validate',
	QUICKENTRY_CHECK_ISSUANCE_READ = 'quickentry-check-issuance-read',
	QUICKENTRY_CHECK_ISSUANCE_WRITE = 'quickentry-check-issuance-write',
	QUICKENTRY_CHECK_ISSUANCE_VALIDATE = 'quickentry-check-issuance-validate',
	QUICKENTRY_SETTING = 'quickentry-setting',

	// SIGN
	SIGN_ACCESS = 'sign-access',
}
